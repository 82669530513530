import {useState, type ReactElement, useEffect} from 'react'
import {
  Container,
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Button,
  Box,
  CircularProgress
} from '@material-ui/core'
import {useSelector} from 'react-redux'
import {getUser} from '../../modules/userSession/slice'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import GetAppIcon from '@material-ui/icons/GetApp'
// import { ModalTicket } from './ModalTicket'
import styles from './Ticket.module.scss'
import {format} from 'date-fns'
import {DownloadPayrollData, fetchPayrollData} from '../../api/payroll'
import {logger} from '../../services'
import {type IPayroll} from '../../types'
import {map} from 'rxjs/operators'
import {toast} from 'react-toastify'
import {TranslationKey} from '../../i18n/translations'
import intlHelper from '../../i18n/intlHelper'
import toastConfig from '../../config/toast'
import SoonTicket from './soonTicket'

interface formTicketValues {
  year: number
  month: number
}
interface monthData {
  value: number
  mes: string
}

interface Month {
  monthNumber: number
  monthName: string
}

function Certifications(): ReactElement {
  const intl = intlHelper.getIntl()
  const user = useSelector(getUser)
  // const [openModal, setOpenModal] = useState(false)
  const [payrolls, setPayrolls] = useState<IPayroll[]>([])
  const [loadingDown, setLoadingDown] = useState<boolean>(false)

  function getLastThreeMonths(selectYear:number = new Date().getFullYear()): Array<{ monthNumber: number, monthName: string }> {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const currentMonth = currentDate.getMonth() + 1 // getMonth() devuelve un valor entre 0 y 11

    const months: Month[] = []
    const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

    if (selectYear === currentYear) {
      // Si el año seleccionado es el año actual
      for (let i = 0; i < 3; i++) {
        const month = currentMonth - i
        if (month > 0) {
          months.push({ monthNumber: month, monthName: monthNames[month - 1] })
        }
      }
    } else if (selectYear === currentYear - 1) {
      // Si el año seleccionado es el año anterior
      if (currentMonth === 1) {
        // Si estamos en enero del año actual
        months.push({ monthNumber: 11, monthName: monthNames[10] })
        months.push({ monthNumber: 12, monthName: monthNames[11] })
      } else if (currentMonth === 2) {
        // Si estamos en febrero del año actual
        months.push({ monthNumber: 12, monthName: monthNames[11] })
      } else {
        // Si estamos en cualquier otro mes del año actual
        for (let i = 0; i < 3; i++) {
          let month = currentMonth - i
          if (month <= 0) {
            month += 12
          }
          months.push({ monthNumber: month, monthName: monthNames[month - 1] })
        }
      }
    } else if (selectYear < currentYear - 1) {
      // Si el año seleccionado es más de un año anterior al actual
      for (let i = 0; i < 3; i++) {
        let month = currentMonth - i
        if (month <= 0) {
          month += 12
        }
        months.push({ monthNumber: month, monthName: monthNames[month - 1] })
      }
    }

    // Ordenar los meses en orden ascendente
    months.sort((a, b) => a.monthNumber - b.monthNumber)

    return months
  }
  
  if (user?.country === 'PE') {
    window.location.assign('https://www.turecibo.com')
  }

  const formik = useFormik({
    initialValues: {
      year: new Date().getFullYear(),
      month: new Date().getMonth()
    },
    validationSchema: Yup.object({
      year: Yup.number().required(),
      month: Yup.number().required()
    }),
    onSubmit: (values) => {
      handleGetPayrolls(values)
    }
  })

  const handleGetPayrolls = (values: formTicketValues): void => {
    const year = values.year
    const month = values.month
    const firstDayOfNextMonth = new Date(year, month, 1)
    // Restar un día (en milisegundos) para obtener el último día del mes actual
    const lastDayOfMonth = new Date(firstDayOfNextMonth.getTime() - 86400000).getDate()
    // const lastDayOfMonth = getLastDayOfMonth(today.getFullYear(), today.getMonth())
    const sendDate = format(
      new Date(year, month - 1, lastDayOfMonth),
      'dd-MM-yyyy'
    )

    fetchPayrollData(sendDate)
      .pipe(map(({response}) => response))
      .subscribe(
        (payroll) => {
          logger.info('fetching payroll', payroll)
          setPayrolls(payroll)
        },
        (err) => {
          logger.error('Error fetching payroll', err)
          toast.error(
            intl?.formatMessage({
              id: TranslationKey.ERROR_PAYROLL
            }),
            toastConfig
          )
        }
      )
  }

  const handleDownloadPayroll = (dataKey: string): void => {
    setLoadingDown(true)
    DownloadPayrollData(dataKey).subscribe(
      ({response}) => {
        logger.info('download payroll', response)

        const fileURL = window.URL.createObjectURL(response)
        const anchorElem = document.createElement('a')

        anchorElem.href = fileURL
        anchorElem.target = '_blank'
        anchorElem.download = 'payroll.pdf'
        anchorElem.style.display = 'none'
        document.body.appendChild(anchorElem)
        anchorElem.click()

        setTimeout(() => {
          document.body.removeChild(anchorElem)
          window.URL.revokeObjectURL(fileURL)
          setLoadingDown(false)
        }, 0)
      },
      (err) => {
        setLoadingDown(false)
        logger.error('Error downloading payroll', err)
        toast.error(
          intl?.formatMessage({
            id: TranslationKey.ERROR_PAYROLL
          }),
          toastConfig
        )
      }
    )
  }
  const getRelevantYears = (): number[] => {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1 // getMonth() devuelve un valor entre 0 y 11
  
    const years = []
    if (currentMonth === 3) {
      // Si el mes actual es marzo, solo retornar el año actual
      years.push(currentYear)
    } else {
      // En cualquier otro mes, retornar el año actual y el anterior
      for (let i = 1; i >= 0; i--) {
        years.push(currentYear - i)
      }
    }
  
    return years
  }
  if (user?.country !== 'MX') {
    return (
      <>
        <div className={styles.banner}>
          <Container>
            <Typography variant="h3">Mi recibo de nómina</Typography>
            <Typography variant="subtitle1">
              Aquí puedes consultar o descargar tus recibos de nómina.
            </Typography>
          </Container>
        </div>
        <Container maxWidth="xs">
          <div className={styles.card}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    Selecciona el año y mes que deseas consultar para descargar tu
                    comprobante de nómina.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl className={styles.formControl} fullWidth>
                    <Typography variant="caption" display="block" gutterBottom>
                      Año
                    </Typography>
                    <Select
                      variant="outlined"
                      name="year"
                      value={formik.values.year}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {getRelevantYears()?.map((item) => <MenuItem key={item.toString()} value={Number(item)}>{item.toString()}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl className={styles.formControl} fullWidth>
                    <Typography variant="caption" display="block" gutterBottom>
                      Mes
                    </Typography>
                    <Select
                      variant="outlined"
                      name="month"
                      value={formik.values.month}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {getLastThreeMonths(formik.values.year)?.map((mes: any, index: number) => (
                        <MenuItem key={index} value={mes.monthNumber !== undefined ? mes.monthNumber : ''}>
                          {mes?.monthName || ''}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{color: '#000', margin: '20px 0'}}
                  >
                    Consultar recibos
                  </Button>
                </Grid>
                {
                  payrolls.length > 0
                    ? (
                      <Box width="100%">
                        <Box
                          width="100%"
                          style={{
                            height: '1px',
                            backgroundColor: '#E5E5E5',
                            marginBottom: '10px'
                          }}
                        ></Box>
                        {payrolls.map((payroll, index) => {
                          const dataKey =
                            payroll.data_key !== undefined ? payroll.data_key : ''
                          return (
                            <Box
                              key={index}
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              mb={2}
                              style={{
                                cursor: 'pointer'
                              }}
                            >
                              <GetAppIcon
                                style={{marginRight: '10px', color: '#4FDBD1'}}
                              />
                              <Typography
                                variant="subtitle1"
                                className="pointer"
                                style={{textDecoration: 'underline'}}
                                onClick={() => {
                                  handleDownloadPayroll(dataKey)
                                }}
                              >
                                {payroll.file_name}
                              </Typography>
                            </Box>
                          )
                        })}

                        <Box
                          width="100%"
                          style={{
                            height: '1px',
                            backgroundColor: '#E5E5E5',
                            marginTop: '10px',
                            marginBottom: '10px'
                          }}
                        ></Box>
                      </Box>
                    )
                    : (
                      <Box width="100%">
                        <Typography variant="subtitle2" align="center">
                          No hay recibos para mostrar, realiza una nueva consulta
                        </Typography>
                      </Box>
                    )
                }
                {loadingDown && (
                  <CircularProgress color="primary" thickness={5} size="1em" />
                )}
                {/* <Box width="100%" textAlign="center" paddingTop="2rem">
                  <Typography
                    variant="subtitle2"
                    className={styles.btnEmail}
                  >
               
                  </Typography> */}

                {/* <Typography
                    variant="subtitle2"
                    className={styles.btnEmail}
                  >
                   Mauricio.Alvor@ab-inbev.com
                  </Typography>
                </Box> */}
              </Grid>
            </form>
            <div className="footer-card"
              style={{background: '#4fdbd1', width: '10rem !important', borderRadius: '0px 0px 24px 24px', height: '5rem', padding: '0px 24px'}}
            >
              <Typography
                variant="subtitle2"
                align="center"
                style={{paddingTop: '7px'}}
              >
                Si tienes alguna duda con tu recibo de pago, favor acercarte al área de <strong>People</strong>.
              </Typography>
            </div>
          </div>
          {/* <ModalTicket openModal={openModal} modalClose={closeModal} /> */}
        </Container>
      </>
    )
  }

  else {
    return (
      <SoonTicket />
    )
  }
}

export default Certifications
